jQuery(function($){
	$('.js-owners-carousel').slick({
		autoplay: false,
		centerMode: true,
        adaptiveHeight: false,
        speed: 300,
		cssEase: 'cubic-bezier(0, 0, 0.04, 0.98)',
		slidesToScroll: 1,
		variableWidth: true,
		infinite: true,
		arrows: false,
		buttons: false,
        rows: 0,
		pauseOnHover: false,
		initialSlide: 3
	});

    $('.js-owners-carousel-prev').click(function(e) {
        e.preventDefault();
        $(this).parents('.block-owners-carousel').find('.js-owners-carousel').slick('slickPrev');
    });
    $('.js-owners-carousel-next').click(function(e) {
        e.preventDefault();
        $(this).parents('.block-owners-carousel').find('.js-owners-carousel').slick('slickNext');
    });
});